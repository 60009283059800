.post
  @apply flex-wrap

  .title
    @apply font-neutral md:font-median mt-[20px] md:mt-[18px] lg:mt-[16px]

  .excerpt
    @apply text-grey-800
    @apply mt-[22px] md:mt-[18px] lg:mt-[16px]
    @apply mb-[32px] md:mb-[24px] md:mb-[40px]

    :global(.dark) &
      @apply text-grey-200

  .button
    @apply w-[180px]

  .authors
    @apply flex ml-[32px]

  .avatar
    @apply ml-[-8px]

  .authorImage
    @apply rounded-full
